import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';



const orderStyle = makeStyles((theme: Theme) =>
  createStyles({
    
    orderContainer: {
      zIndex: 1,
      position: 'absolute',
      left: 0,
      top: 70,
      width: window.innerWidth,
      minHeight: window.innerHeight - 70,
      padding: 6,
      display: 'flex',
      justifyContent: 'center'
    },
    orderLayout: {
      padding: 15,
      width: 800,
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: 6
    },
    title: {
      fontWeight: 'bold', 
      fontSize: 16
    },
    coName: {
      fontWeight: 'bold', 
      fontSize: 14
    },

    headerLabel: {
      flex: 2, 
      fontWeight: 'bold', 
      textAlign: 'end'
    },
    headerValue: {
      flex: 3
    },

    tableHeader: {
      display: 'flex', 
      flexDirection: 'row',
      gap: 10,
      backgroundColor: '#ddd5',
      flexWrap: 'wrap',
      padding: 4,
      height: 30,
      borderTopStyle: 'solid',
      borderTopWidth: 1,
      borderLeftStyle: 'solid',
      borderLeftWidth: 1,
      borderRightStyle: 'solid',
      borderRightWidth: 1,
      borderBottomStyle: 'none',
      borderBottomWidth: 0,
      '@media print' : {
        borderBottomStyle: 'solid',
        borderBottomWidth: 1,
      },
    },
    tableHeaderLabel: {
      fontWeight: 'bold'
    },
    tableRow: {
      display: 'flex', 
      flexDirection: 'row',
      alignItems: 'center',
      gap: 10,
      flexWrap: 'wrap',
      padding: 4,
      minHeight: 30,
      borderTopStyle: 'none',
      borderTopWidth: 0,
      borderLeftStyle: 'solid',
      borderLeftWidth: 1,
      borderRightStyle: 'solid',
      borderRightWidth: 1,
      borderBottomStyle: 'solid',
      borderBottomWidth: 1,
    },

  })
);

export default orderStyle;
