import {actionType} from './actionType';
import Api from '../../lib/api';
import Helper from '@justnice/helper';

export function ownerOrderGetList(query, callback) {
  return function(dispatch) {
    dispatch({type: actionType.order.ownerOrderGetListPending, payload: null});
    
    Api.ownerOrderListGet(query)
    .then(result => {
      if(result && result.status === 200 && result.data) {
        dispatch({
          type: actionType.order.ownerOrderGetListFulfilled,
          payload: result.data
        });
        callback && callback(true, result);
      }
      else {
        dispatch({
          type: actionType.order.ownerOrderGetListRejected,
          payload: Helper.getAxiosError(result)
        });
        callback && callback(false, Helper.getAxiosError(result));
      }
    })
    .catch(err => {
      dispatch({
        type: actionType.order.ownerOrderGetListRejected,
        payload: Helper.getErrorMsg(err)
      });
      callback && callback(false, err);
    });
  }
}

export function ownerOrderItemGetList(orderId, callback) {
  return function(dispatch) {
    dispatch({type: actionType.order.ownerOrderItemGetListPending, payload: null});
    
    Api.ownerOrderItemListGet(orderId)
    .then(result => {
      if(result && result.status === 200 && result.data) {
        dispatch({
          type: actionType.order.ownerOrderItemGetListFulfilled,
          payload: {orderId: orderId, data: result.data}
        });
        callback && callback(true, {orderId: orderId, data: result.data});
      }
      else {
        dispatch({
          type: actionType.order.ownerOrderItemGetListRejected,
          payload: Helper.getAxiosError(result)
        });
        callback && callback(false, Helper.getAxiosError(result));
      }
    })
    .catch(err => {
      dispatch({
        type: actionType.order.ownerOrderItemGetListRejected,
        payload: Helper.getErrorMsg(err)
      });
      callback && callback(false, err);
    });
  }
}

// export function cartItemAdd(item, callback) {
//   return function(dispatch) {
//     dispatch({type: actionType.cart.cartItemAddPending, payload: null});
    
//     Api.cartItemAdd(item)
//     .then(result => {
//       if(result && result.status === 200 && result.data) {
//         dispatch({
//           type: actionType.cart.cartItemAddFulfilled,
//           payload: result.data
//         });
//         callback && callback(true, result);
//       }
//       else {
//         dispatch({
//           type: actionType.cart.cartItemAddRejected,
//           payload: Helper.getAxiosError(result)
//         });
//         callback && callback(false, Helper.getAxiosError(result));
//       }
//     })
//     .catch(err => {
//       dispatch({
//         type: actionType.cart.cartItemAddRejected,
//         payload: Helper.getErrorMsg(err)
//       });
//       callback && callback(false, err);
//     });
//   }
// }
