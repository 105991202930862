import * as React from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/Layout";
import clsx from 'clsx';
import useCommonStyles from '../../assets/jss/commonStyles';
import useOrderStyles from '../../assets/jss/orderStyles';
// import Seo from "../../components/Seo";
import RangeSelector from '../../components/RangeSelector';
import DateRangeSelector from '../../components/DateRangeSelector';
import TableListing from '../../components/TableListing';

import { 
  Button, Paper, Select, 
  FormControl, InputLabel, MenuItem, IconButton, 
  Slide, Input, InputAdornment, Collapse,
  useMediaQuery, Snackbar, CircularProgress
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import * as MUIIcons from '@material-ui/icons';

import Helper from '@justnice/helper';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import { connect } from 'react-redux';
import * as systemActions from '../../redux/actions/systemActions';
import * as orderActions from '../../redux/actions/orderActions';
// import AssetForm from './form';
import Image from '../../components/Image';
import qs from 'qs';

import moment from 'moment';
import UI from '../../lib/ui';
import Api from '../../lib/api';
import {Constant} from '../../lib/constant';
import useWindowResize from "../../components/Hooks/useWindowResize";

import white_logo from '../../images/logo_white_small.png';
import black_logo from '../../images/logo_black_small.png';

const hasWindow = typeof window !== 'undefined';
type Order = 'asc' | 'desc';

const filterFormInitialState = () => {
  return {
    pageNum: {value: 0, touched: false, error: false, message: ''},
    pageSize: {value: 0, touched: false, error: false, message: ''},
    keyword: {value: '', touched: false, error: false, message: ''},
    paymentType: {value: '', touched: false, error: false, message: ''},
    orderStatus: {value: '', touched: false, error: false, message: ''},
    orderDate: {start: '', end: '', touched: false, error: false, message: ''},
    amount: {start: '', end: '', touched: false, error: false, message: ''}
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (a: { [key in Key]: number | string }, b: { [key in Key]: number | string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const OrdersPage : React.FC = (props) => {
  const {t} = useTranslation('order');
  const classes = useCommonStyles();
  const orderClasses = useOrderStyles();
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const windowSize = useWindowResize();

  const [expandFilter, setExpandFilter] = React.useState(false);
  const [clearSearch, setClearSearch] = React.useState(false);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState('');
  const [filterForm, setFilterForm] = React.useState(filterFormInitialState());
  const [selectedOrderID, setSelectedOrderID] = React.useState(null);
  const [errorMsg, setErrorMsg] = React.useState('');
  const [downloadingAsset, setDownloadingAsset] = React.useState('');

  const locale = props['ui'].get('lang');
  const sysList = Helper.getStateAsJSObj(props['system'], 'sysList.data', []);
  const ownerOrder = Helper.getStateAsJSObj(props['order'], 'ownerOrder.data', null);
  const ownerOrderLoading = Helper.getStateAsJSObj(props['order'], 'ownerOrder.loading', false);
  const ownerOrderList = Helper.isNotNullAndUndefined(ownerOrder) && Helper.arrayHasItem(ownerOrder) ? stableSort(ownerOrder, getComparator(order, orderBy)) : []
  const owner = Helper.getStateAsJSObj(props['session'], 'myProfile.data', null);
  const skeletonDummyRec = {
    id: '',
    order_number: '',
    order_date: '',
    charge_amount: '',
    payment_type: '',
    status: ''
  }
  const tableHeight = hasWindow ? window.innerHeight - 180 : 600;
  const tableRowHeight = windowSize.width < Constant.ui.mobileDeviceWidth ? 75 : 50;

  const selectedOrder = Helper.isNotNullAndUndefined(ownerOrder) && Helper.arrayHasItem(ownerOrder) ? ownerOrder.find(item => item.id === selectedOrderID) : null;
  const orderColumns = [];

  //#region Populate the order listing columns
  if(windowSize.width < Constant.ui.mobileDeviceWidth) {
    orderColumns.push({
      width: window.innerWidth * 0.6,
      label: t('listing.field.detail'),
      dataKey: 'id',
      renderer: (id) => {
        let order = ownerOrderList.find(item => item.id === id);
        if(!Helper.isNotNullAndUndefined(order)) {
          return '';
        }

        return <div style={{display: 'flex', flexDirection: 'column'}}>
          <span style={{fontWeight: 'bold'}}>{order.order_number}</span>
          <span>{moment(order.order_date).format('DD MMM yyyy HH:mm:ss')}</span>
          <span>{`${getCodeText('PAYMENT_TYPE', order.payment_type)} ${UI.formatCurrency(order.charge_amount / 100)}`}</span>
        </div>;
      }
    });
    
  }

  if(windowSize.width >= Constant.ui.mobileDeviceWidth) {
    orderColumns.push({
      width: window.innerWidth * 0.2,
      label: t('listing.field.order_num'),
      dataKey: 'order_number',
    });

    orderColumns.push({
      width: window.innerWidth * 0.2,
      label: t('listing.field.order_date'),
      dataKey: 'order_date',
      renderer: (data) => {
        let returnValue ='';
        if(Helper.isNotNullAndUndefined(data) && Helper.stringHasValue(data)) {
          let takenOn = moment(data);
          returnValue = takenOn.format('DD MMM yyyy HH:mm:ss');
        }
        return returnValue;
      }
    });

    orderColumns.push({
      width: window.innerWidth * 0.2,
      label: t('listing.field.amount'),
      dataKey: 'charge_amount',
      renderer: (data) => {
        let returnValue ='';
        if(Helper.isNotNullAndUndefined(data)) {
          returnValue = UI.formatCurrency(data / 100);
        }
        return returnValue;
      }
    });

    orderColumns.push({
      width: window.innerWidth * 0.2,
      label: t('listing.field.payment_type'),
      dataKey: 'payment_type',
      renderer: (data) => {
        return getCodeText('PAYMENT_TYPE', data);
      }
    });
  }

  orderColumns.push({
    width: window.innerWidth * 0.2 > 90 ? window.innerWidth * 0.2 : 90,
    label: t('listing.field.status'),
    dataKey: 'status',
    renderer: (data) => {
      return getCodeText('ORDER_STATUS', data);
    }
  });
  //#endregion

  React.useEffect(() => {
    const sysListObj = Helper.getStateAsJSObj(props['system'], 'sysList', null);
    let queryParam = Helper.stringHasValue(props.location.search) ? qs.parse(props.location.search.substr(1)) : null;

    // Load the user order records when page initial load
    loadOrder((success, result) => {
      // console.log('loadOrder: ', success, result);
      if(success) {
        // If order records loaded and pid is specified, load the respective order item
        if(Helper.isNotNullAndUndefined(result?.data) && 
           Helper.arrayHasItem(result?.data) && 
           Helper.isNotNullAndUndefined(queryParam?.pid) && 
           Helper.stringHasValue(queryParam?.pid)) {
          let order = result.data.find(item => item.payment === queryParam.pid);
          if(Helper.isNotNullAndUndefined(order)) {
            props.dispatch(
              orderActions.ownerOrderItemGetList(order.id, (success2, result2) => {
                // If order item successfully load, then set the selectedOrderID to display the order detail screen
                if(success2) {
                  setSelectedOrderID(order.id);
                }
              })
            );
          }
          else {
            // console.log('Order with PID not found!');
            setErrorMsg(t('error.message.orderNotFoundWithPid'));
          }
        }
        else {
          if(Helper.isNotNullAndUndefined(queryParam?.pid) && 
             Helper.stringHasValue(queryParam?.pid)) {
            setErrorMsg(t('error.message.orderNotFoundWithPid'));
          }
        }
      }
      else {
        setErrorMsg(t('error.message.orderLoadError'));
      }
    });

    // If no system list info and system list is not in loading, load it.
    if(!Helper.isNotNullAndUndefined(sysListObj) || 
       (!Helper.arrayHasItem(sysListObj?.data) && !sysListObj?.loading) ||
       (sysListObj?.locale !== locale && !sysListObj?.loading)) {
      props.dispatch(
        systemActions.sysListGet(locale)
      );
    }
  }, []);

  const onFieldChange = (field: string, value : any) => {
    let localFilterForm = Helper.deepCopy(filterForm);
    localFilterForm[field].value = value;
    localFilterForm[field].touched = true;
    setFilterForm(localFilterForm);
  };

  const onRangeStartChange = (field: string, value: string) => {
    let localFilterForm = Helper.deepCopy(filterForm);
    localFilterForm[field].start = value;
    localFilterForm[field].touched = true;
    if(Helper.stringHasValue(localFilterForm[field].end)) {
      validateForm(localFilterForm);
    }
    else {
      setFilterForm(localFilterForm);
    }
  };

  const onRangeEndChange = (field: string, value: string) => {
    let localFilterForm = Helper.deepCopy(filterForm);
    localFilterForm[field].end = value;
    localFilterForm[field].touched = true;
    validateForm(localFilterForm);
  };

  const validateForm = (form=null) => {
    let localForm = Helper.isNotNullAndUndefined(form) ? form : Helper.deepCopy(filterForm);
    let formIsValid = true;

    let fields = Object.keys(localForm);

    fields.forEach(item => {
      localForm[item].error = false;
      localForm[item].message = '';

      if(localForm[item].touched && !['keyword', 'paymentType', 'orderStatus'].includes(item)) {

        if(item === 'orderDate') {
          if(!Helper.stringHasValue(localForm[item].start) && Helper.stringHasValue(localForm[item].end)) {
            localForm[item].error = true;
            localForm[item].message = t('error.message.startValue');
            formIsValid = false;
          }

          if(Helper.stringHasValue(localForm[item].start) && !Helper.stringHasValue(localForm[item].end)) {
            localForm[item].error = true;
            localForm[item].message = t('error.message.endValue');
            formIsValid = false;
          }

          if(!Helper.stringHasValue(localForm[item].start) && !Helper.stringHasValue(localForm[item].end)) {
            localForm[item].error = true;
            localForm[item].message = t('error.message.noValue');
            formIsValid = false;
          }

          if(Helper.stringHasValue(localForm[item].start) && Helper.stringHasValue(localForm[item].end)) {
            let start = new Date(localForm[item].start);
            let end = new Date(localForm[item].end);
            if(start.getTime() > end.getTime()) {
              localForm[item].error = true;
              localForm[item].message = t('error.message.invalidRange');
              formIsValid = false;
            }
          }
        }
        else {
          if(!Helper.stringHasValue(localForm[item].start) && Helper.stringHasValue(localForm[item].end)) {
            localForm[item].error = true;
            localForm[item].message = t('error.message.startValue');
            formIsValid = false;
          }

          if(Helper.stringHasValue(localForm[item].start) && !Helper.stringHasValue(localForm[item].end)) {
            localForm[item].error = true;
            localForm[item].message = t('error.message.endValue');
            formIsValid = false;
          }

          if(!Helper.stringHasValue(localForm[item].start) && !Helper.stringHasValue(localForm[item].end)) {
            localForm[item].error = true;
            localForm[item].message = t('error.message.noValue');
            formIsValid = false;
          }

          if(Helper.stringHasValue(localForm[item].start) && Helper.stringHasValue(localForm[item].end)) {
            let start = eval(localForm[item].start);
            let end = eval(localForm[item].end);
            if(start > end) {
              localForm[item].error = true;
              localForm[item].message = t('error.message.invalidRange');
              formIsValid = false;
            }
          }
        }
      }
    });

    setFilterForm(localForm);
    return formIsValid;
  }

  const clearFilterForm = () => {
    setFilterForm(filterFormInitialState());
  }

  const loadOrder = (callback=undefined) => {
    props.dispatch(
      orderActions.ownerOrderGetList(filterForm, callback)
    );
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const onRowClick = (row) => {
    if(!ownerOrderLoading) {
      setSelectedOrderID(row.id);
      requestAnimationFrame(() => {
        if(!Helper.isNotNullAndUndefined(row.order_items) || !Helper.arrayHasItem(row.order_items)) {
          props.dispatch(
            orderActions.ownerOrderItemGetList(row.id)
          );
        }
      });
    }
  }

  const getCodeText = (category, code) => {
    if(sysList.length <= 0)
      return '';

    let codeItem = sysList.find(item => item.category === category && item.code === code);
    if(!Helper.isNotNullAndUndefined(codeItem)) {
      return '';
    }

    return codeItem.text
  }


  //#region Populate priceList
  const priceList = [];
  for(let idx=0; idx < 10; idx++) {
    priceList.push(
      <MenuItem key={`price_${idx}_x10`} value={(idx === 0 ? 1 : idx * 10).toString()}>
        {`$${(idx === 0 ? 1 : idx * 10).toLocaleString()}`}
      </MenuItem>
    );
  }
  for(let idx=1; idx < 10; idx++) {
    priceList.push(
      <MenuItem key={`price_${idx}_x100`} value={(idx * 100).toString()}>
        {`$${(idx * 100).toLocaleString()}`}
      </MenuItem>
    );
  }
  for(let idx=1; idx <= 10; idx++) {
    priceList.push(
      <MenuItem key={`price_${idx}_x1000`} value={(idx * 1000).toString()}>
        {`$${(idx * 1000).toLocaleString()}`}
      </MenuItem>
    );
  }
  //#endregion

  const populateOrderItems = (printing) => {
    const orderItemRows = [];
    if(Helper.isNotNullAndUndefined(selectedOrder)) {
      if(Helper.isNotNullAndUndefined(selectedOrder.order_items) && Helper.arrayHasItem(selectedOrder.order_items)) {
        selectedOrder.order_items.forEach(item => {
          orderItemRows.push(
            <div className={orderClasses.tableRow} key={`order_item_${item.id}`}>
              <div className={clsx(classes.col)} style={{flex: 1, alignItems: 'center'}}>
                <span>{item.item_sn}</span>
              </div>
              {
                (printing || windowSize.width >= Constant.ui.mobileDeviceWidth) &&
                <>
                  <div className={clsx(classes.col)} style={{flex: 5}}>
                    <span>{item.asset?.title}</span>
                  </div>
                  <div className={clsx(classes.col)} style={{flex: 3, alignItems: 'center'}}>
                    <span>{`${item.asset?.image_size?.width?.toString()} x ${item.asset?.image_size?.height?.toString()}`}</span>
                  </div>
                  <div className={clsx(classes.col)} style={{flex: 3, alignItems: 'center'}}>
                    <span>{item.asset?.mime_type}</span>
                  </div>
                  <div className={clsx(classes.col)} style={{flex: 4, alignItems: 'center'}}>
                    <span>{getCodeText('LICENSE_TYPE', item.license_type)}</span>
                    {
                      item.license_type === 'WEB' &&
                      <span>{item.license_info?.website}</span>
                    }
                    {
                      item.license_type === 'ADVERTISING' &&
                      <span>{item.license_info?.coName}</span>
                    }
                  </div>
                  <div className={clsx(classes.col)} style={{flex: 3, alignItems: 'end'}}>
                    <span>{UI.formatCurrency(item.amount)}</span>
                  </div>
                </>
              }
              {
                !printing && windowSize.width < Constant.ui.mobileDeviceWidth &&
                <div className={clsx(classes.col)} style={{flex: 18}}>
                  <span>{item.asset?.title}</span>
                  <span>{`${t('order.detail.items.size')}: ${item.asset?.image_size?.width?.toString()} x ${item.asset?.image_size?.height?.toString()}`}</span>
                  <span>
                    {`${getCodeText('LICENSE_TYPE', item.license_type)} ${item.license_type === 'WEB' ? ' - ' + item.license_info?.website : (item.license_type === 'ADVERTISING' ? ' - ' + item.license_info?.coName : '')}`}
                  </span>
                  <span>{UI.formatCurrency(item.amount)}</span>
                </div>
              }
              <div className={clsx(classes.col)} style={{flex: 1, alignItems: 'center'}}>
                {
                  selectedOrder?.status === 'FULFILLED' && !printing &&
                  <>
                    {
                      downloadingAsset === item.id ?
                      <CircularProgress size={28} />
                      :
                      <IconButton 
                        size={'small'}
                        onClick={() => assetDownload(item.id, item.asset?.filename)}>
                        <MUIIcons.CloudDownload />
                      </IconButton>
                    }
                  </>
                }
              </div>
            </div>
          );
        });

        orderItemRows.push(
          <div className={orderClasses.tableRow} key={`order_item_total`}>
            <div className={clsx(classes.col)} style={{flex: 16, alignItems: 'end'}}>
              <span className={orderClasses.tableHeaderLabel}>{t('order.detail.items.total')}</span>
            </div>
            <div className={clsx(classes.col)} style={{flex: 3, alignItems: 'end'}}>
              <span className={orderClasses.tableHeaderLabel}>{UI.formatCurrency(selectedOrder.charge_amount / 100)}</span>
            </div>
            <div className={clsx(classes.col)} style={{flex: 1, alignItems: 'center'}}>
              
            </div>
          </div>
        );
      }
      else {
        orderItemRows.push(
          <div className={orderClasses.tableRow}  key={`order_item_no_item`}>
            <div className={clsx(classes.col)} style={{flex: 1, alignItems: 'center'}}>
              <span className={orderClasses.tableHeaderLabel}>{t('order.detail.items.no_item')}</span>
            </div>
          </div>
        );
      }
    }

    return orderItemRows;
  }

  const populateOrderDetail = (printing) => {
    const orderUIElements = [];

    orderUIElements.push(
      <div className={classes.formRow} key='orderDetailRow1'>
        <div className={clsx(classes.formCol)} style={{flex: 1, justifyContent: 'center'}} >
          <Image
            src={printing ? black_logo : (prefersDarkMode ? white_logo : black_logo)}
            height={67}
            width={200}
            alt='JustShoot.It'
          />
        </div>
        <div className={clsx(classes.formCol)} style={{flex: 1, textAlign: 'right'}} >
          <span className={orderClasses.title}>{t('order.detail.title')}</span>
          <span className={orderClasses.coName}>{t('order.detail.co_name')}</span>
          <span>{t('order.detail.co_add1')}</span>
          <span>{t('order.detail.co_add2')}</span>
        </div>
      </div>);

    orderUIElements.push(
      <div className={classes.row} key='orderDetailRow2'>
        <div className={clsx(classes.row)} style={{flex: 1}}>
          <span className={orderClasses.headerLabel}>{t('order.detail.order_num')}</span>
          <span className={orderClasses.headerValue}>{selectedOrder?.order_number}</span>
        </div>
        <div className={clsx(classes.row)} style={{flex: 1}}>
          <span className={orderClasses.headerLabel}>{t('order.detail.cust_name')}</span>
          <span className={orderClasses.headerValue}>{`${owner?.name?.first_name} ${owner?.name?.last_name}`}</span>
        </div>
      </div>);

    orderUIElements.push(
      <div className={classes.row} key='orderDetailRow3'>
        <div className={clsx(classes.row)} style={{flex: 1}}>
          <span className={orderClasses.headerLabel}>{t('order.detail.order_date')}</span>
          <span className={orderClasses.headerValue}>{UI.formatShortDate(selectedOrder?.order_date, locale)}</span>
        </div>
        <div className={clsx(classes.row)} style={{flex: 1}}>
          <span className={orderClasses.headerLabel}>{t('order.detail.cust_email')}</span>
          <span className={orderClasses.headerValue}>{owner?.user?.email}</span>
        </div>
      </div>);

    orderUIElements.push(
      <div className={classes.row} key='orderDetailRow4'>
        <div className={clsx(classes.row)} style={{flex: 1}}>
          <span className={orderClasses.headerLabel}>{t('order.detail.order_amount')}</span>
          <span className={orderClasses.headerValue}>{UI.formatCurrency(selectedOrder?.charge_amount / 100)}</span>
        </div>
        <div className={clsx(classes.row)} style={{flex: 1}}>
          <span className={orderClasses.headerLabel}>{t('order.detail.cust_address')}</span>
          <span className={orderClasses.headerValue}>{owner?.addresses?.length > 0 ? owner?.addresses[0].line1 : ''}</span>
        </div>
      </div>);

    orderUIElements.push(
      <div className={classes.row} key='orderDetailRow5'>
        <div className={clsx(classes.row)} style={{flex: 1}}>
          <span className={orderClasses.headerLabel}>{t('order.detail.payment_type')}</span>
          <span className={orderClasses.headerValue}>{getCodeText('PAYMENT_TYPE', selectedOrder?.payment_type) + (selectedOrder?.payment_type === 'CREDITCARD' ? ` - ${selectedOrder?.card_type} ...${selectedOrder?.card_num}` : '')}</span>
        </div>
        <div className={clsx(classes.row)} style={{flex: 1}}>
          <span className={orderClasses.headerLabel}> </span>
          <span className={orderClasses.headerValue}>{owner?.addresses?.length > 0 ? owner?.addresses[0].line2 : ''}</span>
        </div>
      </div>);

    orderUIElements.push(
      <div className={classes.row} key='orderDetailRow6'>
        <div className={clsx(classes.row)} style={{flex: 1}}>
          <span className={orderClasses.headerLabel}>{t('order.detail.order_status')}</span>
          <span className={orderClasses.headerValue}>{getCodeText('ORDER_STATUS', selectedOrder?.status)}</span>
        </div>
        <div className={clsx(classes.row)} style={{flex: 1}}>
          <span className={orderClasses.headerLabel}> </span>
          <span className={orderClasses.headerValue}>{owner?.addresses?.length > 0 ? `${getCodeText('COUNTRY', owner?.addresses[0].country_code)} ${owner?.addresses[0].postal_code}` : ''}</span>
        </div>
      </div>);

    orderUIElements.push(
      <div className={classes.row} key='orderDetailRow7' style={{marginTop: 20, marginBottom: 20}}>
        <div className={clsx(classes.col)}>
          <span className={orderClasses.headerLabel} style={{textAlign: 'left'}}>{t('order.detail.customer_note')}</span>
          <span className={orderClasses.headerValue}>{t(`order.detail.customer_note.${selectedOrder?.status}`)}</span>
        </div>
      </div>);
      
    orderUIElements.push(
      <div className={classes.row} key='orderDetailRow8'>
        <div className={clsx(classes.col)}>
          <span className={orderClasses.headerLabel} style={{textAlign: 'left'}}>{t('order.detail.items')}</span>
        </div>
      </div>);

    orderUIElements.push(
      <div className={orderClasses.tableHeader} key='orderDetailRow9'>
        <div className={clsx(classes.col)} style={{flex: 1, alignItems: 'center'}}>
          <span className={orderClasses.tableHeaderLabel}>{t('order.detail.items.sn')}</span>
        </div>
        {
          (printing || windowSize.width >= Constant.ui.mobileDeviceWidth) &&
          <>
            <div className={clsx(classes.col)} style={{flex: 5}}>
              <span className={orderClasses.tableHeaderLabel}>{t('order.detail.items.title')}</span>
            </div>
            <div className={clsx(classes.col)} style={{flex: 3, alignItems: 'center'}}>
              <span className={orderClasses.tableHeaderLabel}>{t('order.detail.items.size')}</span>
            </div>
            <div className={clsx(classes.col)} style={{flex: 3, alignItems: 'center'}}>
              <span className={orderClasses.tableHeaderLabel}>{t('order.detail.items.mime_type')}</span>
            </div>
            <div className={clsx(classes.col)} style={{flex: 4, alignItems: 'center'}}>
              <span className={orderClasses.tableHeaderLabel}>{t('order.detail.items.license_type')}</span>
            </div>
            <div className={clsx(classes.col)} style={{flex: 3, alignItems: 'end'}}>
              <span className={orderClasses.tableHeaderLabel}>{t('order.detail.items.amount')}</span>
            </div>
          </>
        }
        {
          !printing && windowSize.width < Constant.ui.mobileDeviceWidth &&
          <div className={clsx(classes.col)} style={{flex: 18}}>
            <span className={orderClasses.tableHeaderLabel}>{t('order.detail.items.detail')}</span>
          </div>
        }
        <div className={clsx(classes.col)} style={{flex: 1, alignItems: 'center'}}>
          <span className={orderClasses.tableHeaderLabel}>{' '}</span>
        </div>
      </div>);
    
    return orderUIElements.concat(populateOrderItems(printing));
  }

  const assetDownload = async (orderItemId, filename) => {
    try {
      setDownloadingAsset(orderItemId);
      let asset = await Api.assetDownload(orderItemId);
      // console.log('assetDownload: ', asset);

      if(asset?.status === 200) {
        // 1. Create blob link to download
        const url = window.URL.createObjectURL(new Blob([asset.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename);

        // 2. Append to html page
        document.body.appendChild(link);

        // 3. Force download
        link.click();

        // 4. Clean up and remove the link
        link.parentNode.removeChild(link);
      }
      else {
        setErrorMsg(t('error.message.orderItemDownloadError'));
      }

      setDownloadingAsset('');
    } catch (error) {
      setErrorMsg(Helper.getErrorMsg(error));
      setDownloadingAsset('');
    }
  }

  if(clearSearch) {
    if(validateForm()) {
      loadOrder();
    }
    setClearSearch(false);
  }

  return (
    <Layout title={t('pageTitle')}>
      {/* <Seo title={t('title')} /> */}
      {/* For printing */}
      <div className={classes.printContent}>
        <div id={'orderToPrint'} >
          {populateOrderDetail(true)}
        </div>
      </div>

      <Paper variant="outlined" className={clsx(classes.form, classes.nonPrintContent)} style={{marginTop: 0, marginBottom: 0}}>
        <div className={classes.formRow} style={{marginBottom: 0}}>
          <FormControl style={{display: 'flex', flex: 1}}>
            <InputLabel htmlFor="input-with-icon-adornment">{t('filter.header')}</InputLabel>
            <Input
              id="input-with-icon-adornment"
              value={filterForm.keyword.value}
              onChange={event => onFieldChange('keyword', event.target.value)}
              onKeyPress={(e) => {
                if(e.key === 'Enter') {
                  if(validateForm()) {
                    loadOrder();
                  }
                }
              }}
              endAdornment={
                <>
                  {
                    Helper.stringHasValue(filterForm.keyword.value) &&
                    <InputAdornment position="end">
                      <IconButton onClick={() => {
                        onFieldChange('keyword', '');
                        setClearSearch(true);
                      }}>
                        <MUIIcons.Close fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  }
                  <InputAdornment position="end">
                    <MUIIcons.Search />
                  </InputAdornment>
                </>
              }
            />
          </FormControl>
          <IconButton onClick={() => setExpandFilter(!expandFilter)}>
            {
              !expandFilter ?
              <MUIIcons.ExpandMore />
              :
              <MUIIcons.ExpandLess />
            }
          </IconButton>
        </div>
        <Collapse in={expandFilter} style={{marginTop: !expandFilter ? 0 : 20}}>
          <div className={classes.formRow}>
            <div className={clsx(classes.formCol)} style={{flex: 1, minWidth: 400}} >
              <DateRangeSelector 
                id={'filter.field.orderDate'} 
                labelId={'filter.field.label.orderDate'}
                label={t('filter.field.label.orderDate')}
                value={[filterForm.orderDate.start, filterForm.orderDate.end]}
                type={'date'}
                error={filterForm.orderDate.error}
                errorMsg={filterForm.orderDate.message}
                onStartChanged={(value) => onRangeStartChange('orderDate', value)}
                onEndChanged={(value) => onRangeEndChange('orderDate', value)}
              />
            </div>
            <div className={clsx(classes.formCol)} style={{flex: 1, minWidth: 200}} >
              <RangeSelector 
                id={'filter.field.amount'} 
                labelId={'filter.field.label.amount'}
                label={t('filter.field.label.amount')}
                value={[filterForm.amount.start, filterForm.amount.end]}
                startRangeList={priceList}
                endRangeList={priceList}
                error={filterForm.amount.error}
                errorMsg={filterForm.amount.message}
                onStartChanged={(value) => onRangeStartChange('amount', value)}
                onEndChanged={(value) => onRangeEndChange('amount', value)}
              />
            </div>
          </div>
          <div className={classes.formRow}>
            <div className={clsx(classes.formCol)} style={{flex: 1, minWidth: 200}} >
              <FormControl variant="outlined">
                <InputLabel id="paymentTypeLabel">{t('filter.field.label.paymentType')}</InputLabel>
                <Select
                  labelId="paymentTypeLabel"
                  id="paymentType"
                  value={filterForm.paymentType.value}
                  onChange={event => onFieldChange('paymentType', event.target.value as string)}
                  // onBlur={(event) => validateFormField('imageFormat', event.target.value)}
                  label={t('filter.field.label.paymentType')}
                >
                  {
                    sysList
                    .filter(item => item.category === 'PAYMENT_TYPE')
                    .map(item => (
                      <MenuItem value={item.code} key={`PAYMENT_TYPE_${item.code}`}>
                        {item.text}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
            <div className={clsx(classes.formCol)} style={{flex: 1, minWidth: 200}} >
              <FormControl variant="outlined">
                <InputLabel id="orderStatusLabel">{t('filter.field.label.orderStatus')}</InputLabel>
                <Select
                  labelId="orderStatusLabel"
                  id="orderStatus"
                  value={filterForm.orderStatus.value}
                  onChange={event => onFieldChange('orderStatus', event.target.value as string)}
                  // onBlur={(event) => validateFormField('orderStatus', event.target.value)}
                  label={t('filter.field.label.orderStatus')}
                >
                  {
                    sysList
                    .filter(item => item.category === 'ORDER_STATUS')
                    .map(item => (
                      <MenuItem value={item.code} key={`ORDER_STATUS${item.code}`}>
                        {item.text}
                      </MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </div>
          </div>
          
          
          <div style={{display: 'flex', justifyContent: 'center', flexDirection: 'row'}}>
            <Button variant="contained" onClick={() => {clearFilterForm()}}>
              {t('button.clear')}
            </Button>
            <div style={{width: 30}} />
            <Button variant="contained" color="primary" onClick={() => {
              if(validateForm()) {
                loadOrder()
              }
            }}>
              {t('button.apply')}
            </Button>
          </div>
        </Collapse>
      </Paper>

      <Paper variant="outlined" 
        className={clsx(classes.form, classes.nonPrintContent)}
        style={{ height: tableHeight, width: '100%' }}
      >
        <TableListing
          tableHeight={tableHeight}
          rowHeight={tableRowHeight}
          rowCount={!ownerOrderLoading ? ownerOrderList.length : (Math.floor(tableHeight / tableRowHeight))}
          rowGetter={({ index }) => !ownerOrderLoading ? ownerOrderList[index] : skeletonDummyRec}
          columns={orderColumns}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          onRowClick={({ index }) => onRowClick(ownerOrderList[index])}
          showSkeleton={ownerOrderLoading}
        />
      </Paper>

      <Slide direction="left" in={Helper.isNotNullAndUndefined(selectedOrderID) } mountOnEnter unmountOnExit>
        <Paper elevation={4} className={clsx(orderClasses.orderContainer, classes.nonPrintContent)}>
          <div className={clsx(orderClasses.orderLayout, classes.nonPrintContent)}>
            {populateOrderDetail(false)}
            <div className={classes.row} style={{marginTop: 50, justifyContent: 'center'}}>
              <Button variant="contained" onClick={() => {setSelectedOrderID(null)}}>
                {t('button.close')}
              </Button>

              <Button variant="contained" onClick={() => { window.print(); }}>
                {t('button.print')}
              </Button>
            </div>
            
          </div>
        </Paper>
      </Slide>

      <Snackbar open={errorMsg.length > 0} autoHideDuration={8000} onClose={() => setErrorMsg('')}>
        <Alert onClose={() => setErrorMsg('')} severity="error">
          {errorMsg}
        </Alert>
      </Snackbar>
    </Layout>
  );
}

export default connect((state) => {
  return {
    ui: state['ui'],
    session: state['session'],
    system: state['system'],
    page: state['page'],
    gallery: state['gallery'],
    order: state['order']
  };
})(OrdersPage);

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
