import * as React from "react";
import PropTypes from "prop-types";
import { FormControl, Select, FormHelperText, TextField } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as MUIIcons from '@material-ui/icons';
import * as MUIColors from '@material-ui/core/colors';

const useCompStyle = makeStyles((theme: Theme) =>
  createStyles({
    component: {
      borderWidth: 0.5, 
      borderRadius: 4, 
      borderColor: theme.palette.grey[700],
      borderStyle: 'solid',
      display: 'flex', 
      flexDirection: 'column',
      "&:hover": {
        borderColor: theme.palette.grey[100]
      }
    }
  })
);


const DateRangeSelector = (props) => {
  const classes = useCompStyle();

  return(
    <fieldset className={classes.component}>
      <legend>{props.label}</legend>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <TextField
          id={`${props.id}.start`}
          variant={'standard'}
          style={{flex: 2}}
          type={props.type}
          value={props.value[0]}
          onChange={event => {
            props.onStartChanged && props.onStartChanged(event.target.value as string)
          }}
          // onBlur={(event) => validateFormField('takenOn', event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          />
        <MUIIcons.ArrowRightAlt style={{flex: 0.5}} />
        <TextField
          id={`${props.id}.end`}
          variant={'standard'}
          style={{flex: 2}}
          type={props.type}
          value={props.value[1]}
          onChange={event => {
            props.onStartChanged && props.onEndChanged(event.target.value as string)
          }}
          // onBlur={(event) => validateFormField('takenOn', event.target.value)}
          InputLabelProps={{
            shrink: true,
          }}
          />
      </div>
      {
        props.error &&
        <FormHelperText style={{color: MUIColors.red[500]}}>{props.errorMsg}</FormHelperText>
      }
    </fieldset>
  );
}

DateRangeSelector.propTypes = {
  id: PropTypes.string.isRequired,
  labelId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.array,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  onStartChanged: PropTypes.func,
  onEndChanged: PropTypes.func,
  type: PropTypes.string
}

DateRangeSelector.defaultProps = {
  error: false,
  errorMsg: '',
  value: ['', ''],
  type: 'datetime-local'
}

export default DateRangeSelector;
