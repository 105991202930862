import moment from 'moment';
import 'moment/locale/zh-cn';
import 'moment/locale/en-sg';


export default class UI {
  
  static formatShortDate(date, lang, format='LL', timeZoned=false) {
    if(lang === 'en')
      moment.locale('en-sg');
    else
      moment.locale('zh-cn');

    if(date !== undefined && date !== null) {
      var value = timeZoned ? moment(date) : moment(date).utc();
      return value.format(format);
    }
    else
      return '';
  }

  static formatCurrency(amount, decimalPlace=2, sign='$', groupSize=3) {
    if(amount !== undefined && amount !== null) {
      var re = '\\d(?=(\\d{' + (groupSize || 3) + '})+' + (decimalPlace > 0 ? '\\.' : '$') + ')';
      return sign + amount.toFixed(Math.max(0, ~~decimalPlace)).replace(new RegExp(re, 'g'), '$&,');
    }
    else
      return '';
  }

  static validatePhoneNumber(phone) {
    return phone.match(/^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]{7,10}$/g) !== null;
  }

  static validateCreditCardNumber(value) {
    // Accept only digits, dashes or spaces
    //if (/[^0-9-\s]+/.test(value)) return false;
    
    // Accept only digits
    if (/[^0-9]+/.test(value)) return false;
  
    // The Luhn Algorithm. It's so pretty.
    let nCheck = 0, bEven = false;
    value = value.replace(/\D/g, "");
  
    for (var n = value.length - 1; n >= 0; n--) {
      var cDigit = value.charAt(n),
          nDigit = parseInt(cDigit, 10);
  
      if (bEven && (nDigit *= 2) > 9) nDigit -= 9;
  
      nCheck += nDigit;
      bEven = !bEven;
    }
  
    return (nCheck % 10) === 0;
  }

  static validateEmail(email) {
    return email.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) !== null;
  }

  static maskText(text, head=1, tail=1, maskChar='*') {
    let maskedText = '';

    if(text !== null && text.trim().length > 0) {
      maskedText = text.trim();
      if(head + tail >= maskedText.length) {
        maskedText = maskedText.padStart(maskedText.length, maskChar);
      }
      else {
        let arrText = Array.from(maskedText);
        for(let idx=head; idx < (arrText.length - tail); idx++) {
          arrText[idx] = maskChar;
        }

        maskedText = arrText.join('');
      }
    }

    return maskedText;
  }

  static populateItemName(item, ancestorTabletPostFix) {
    let name = '';

    if(item.RecType === 'WS') {
      if(item.DisplayName !== null && item.DisplayName !== undefined && item.DisplayName.trim().length > 0) { 
        name = item.DisplayName.trim();
      }

      if(name.length <= 0 && item.OtherDisplayName !== null && item.OtherDisplayName !== undefined && item.OtherDisplayName.trim().length > 0) {
        name += item.OtherDisplayName.trim();
      }

      if(item.NamePostFix !== null && item.NamePostFix !== undefined && item.NamePostFix.trim().length > 0) {
        name += item.NamePostFix.trim();
      }
    }
    else {
      if(item.Surname1 !== null && item.Surname1 !== undefined && item.Surname1.trim().length > 0) { 
        name = item.Surname1.trim();
      }

      if(item.Surname2 !== null && item.Surname2 !== undefined && item.Surname2.trim().length > 0) { 
        if(name.length > 0)
          name += '/';
        name += item.Surname2.trim();
      }

      name += ancestorTabletPostFix;
    }
    return name;
  }
}