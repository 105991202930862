import * as React from "react";
import Skeleton from '@material-ui/lab/Skeleton';
import Helper from '@justnice/helper';

interface ImageProps {
  //children: React.ReactNode;
  src: any;
  alt?: string;
  height?: number;
  width?: number;
}

// Use React.memo to wrap around the img component to prevent
// re-rendering when other state change other than the selectedFile state change.
export default React.memo(function Image(props: ImageProps) {
  const [imageLoaded, setImageLoaded] = React.useState(false);

  return <>
    {
      !imageLoaded &&
      <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
        <Skeleton variant="rect" animation="wave" style={{ height: props.height, width: Helper.isNotNullAndUndefined(props.width) ? props.width : (Helper.isNotNullAndUndefined(props.height) ? props.height * 2 : 1024)}} />
      </div>
    }
    <img 
      src={typeof props.src === 'string' ? props.src : URL.createObjectURL(props.src)} 
      alt={props.alt}
      onLoad={({target:img}) => { setImageLoaded(true) }}
      style={{height: props.height, width: props.width, display: imageLoaded ? undefined : 'none'}} 
    />
  </>;
});