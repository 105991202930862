
export const Constant = {
  config: {
    encSecret: '68235669-D50E-4CD4-B562-B4EE55E70034',
    // baseURL: 'http://192.168.1.89:5000/api/v1', // Moved to .env.* environment file
    // apiKey: { // No apiKey require as the apiKey is passed via the client
    //   levelAll: 'e85ba9c8-cb36-4589-9c8d-1fd89598d6e9',
    //   level2: '8099145f-d61d-4edd-b4e0-a6ba3fa3e657',
    //   level4: '11d0bb87-bb74-41e5-9842-c2e7d91e70e8',
    //   level6: '8393d1ae-1269-4f19-8cc9-18016b8f3fa3'
    // },
    idleTimer: 1000 * 60 * 2 // 2 minute
  },
  mimeType: {
    pdf: 'application/pdf',
    png: 'image/png',
    bmp: 'image/bmp',
    jpeg: 'image/jpeg',
    heic: 'image/heic'
  },
  ancestorTablet: {
    templateRatio: 2.71, 
  },
  ui: {
    defaultPageSize: 20,
    defaultLongDateFormat: 'DD MMM YYYY hh:mm:ss A',
    defaultLongDateFormat24Hrs: 'DD MMM YYYY HH:mm:ss',
    defaultShortDateFormat: 'DD MMM YYYY',
    defaultShortTimeFormat: 'hh:mm',
    defaultTimeFormat: 'hh:mm:ss A',
    mobileDeviceWidth: 500
  }
}
