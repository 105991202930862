import * as React from "react";
import PropTypes from "prop-types";
import { FormControl, Select, FormHelperText } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as MUIIcons from '@material-ui/icons';
import * as MUIColors from '@material-ui/core/colors';

const useCompStyle = makeStyles((theme: Theme) =>
  createStyles({
    component: {
      borderWidth: 0.5, 
      borderRadius: 4, 
      borderColor: theme.palette.grey[700],
      borderStyle: 'solid',
      display: 'flex', 
      flexDirection: 'column',
      "&:hover": {
        borderColor: theme.palette.grey[100]
      }
    }
  })
);


const RangeSelector = (props) => {
  const classes = useCompStyle();

  return(
    <fieldset className={classes.component}>
      <legend>{props.label}</legend>
      <div style={{display: 'flex', flexDirection: 'row'}}>
        <FormControl variant="standard" style={{flex: 2}}>
          <Select
            labelId={`${props.labelId}.start`}
            id={`${props.id}.start`}
            value={props.value[0]}
            onChange={event => {
              props.onStartChanged && props.onStartChanged(event.target.value as string)
            }}
          >
            {props.startRangeList}
          </Select>
        </FormControl>
        <MUIIcons.ArrowRightAlt style={{flex: 1}} />
        <FormControl variant="standard" style={{flex: 2}}>
          <Select
            labelId={`${props.labelId}.end`}
            id={`${props.id}.end`}
            value={props.value[1]}
            onChange={event => {
              props.onEndChanged && props.onEndChanged(event.target.value as string)
            }}
          >
            {props.endRangeList}
          </Select>
        </FormControl>
      </div>
      {
        props.error &&
        <FormHelperText style={{color: MUIColors.red[500]}}>{props.errorMsg}</FormHelperText>
      }
    </fieldset>
  );
}

RangeSelector.propTypes = {
  id: PropTypes.string.isRequired,
  labelId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.array,
  startRangeList: PropTypes.array.isRequired,
  endRangeList: PropTypes.array.isRequired,
  error: PropTypes.bool,
  errorMsg: PropTypes.string,
  onStartChanged: PropTypes.func,
  onEndChanged: PropTypes.func
}

RangeSelector.defaultProps = {
  error: false,
  errorMsg: '',
  value: ['', '']
}

export default RangeSelector;
